
.select-product {
  transition: all 250ms;
  padding-bottom: 15px;
  &:hover {
    box-shadow:
        0px 3px 2px -1px rgba(255, 255, 255,0.2),
        0px 3px 13px 0px rgba(0, 74, 119,0.14),
        0px 0px 0px 0px rgba(0, 74, 119,0.12);
  }

  a {
    text-decoration: none;
  }

  .container-img {
    padding: 0 0px;
    text-align: center;
    width: 100%;
    height: 320px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    box-sizing: border-box;
    @media (min-width: 600px) {
      & {
        padding: 0 15px;
        align-items: center;
      }
    }
    img {
      max-width: 100%;
      height: auto;
      max-height: auto;

      @media (min-width: 600px) {
        & {
          max-height: 305px;
          // height: 100%;
        }
      }
    }

    & > div {
      position: relative;
    }

    .img-main {
      position: relative;
      opacity: 1;
      transition: opacity 500ms;
    }

    .img-hover {
      position: absolute;
      width: 100%;
      left: 0;
      opacity: 0;
      transition: opacity 500ms;
    }
  }

  .name {
    margin-top: 10px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 600;
    color: black;
    width: 100%;
    // color: #d6005a;
  }

  .code {
    margin-top: 0px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 600;
    color: #670000;
    width: 100%;
  }

  &:hover {
    .img-main {
      position: relative;
      opacity: 0;
    }

    .img-hover {
      opacity: 1;
    }
  }

  .textiles {
    margin-bottom: 20px;
    color: black;
    background-color: #001b8c21;
    border-radius: 5px;
    display: initial;
    padding: 1px 10px;
    span {
      img {
        border-radius: 50px;
        width: 10px;
        height: 10px;
      }
    }
  }
}