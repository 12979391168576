.btn-container-textile {
  .btn-textile {
    cursor: pointer;
    box-shadow:
        0px 3px 2px -1px rgba(255, 255, 255,0.2),
        0px 3px 13px 0px rgba(0, 74, 119,0.14),
        0px 0px 0px 0px rgba(0, 74, 119,0.12);
    border-radius: 5px;
    padding: 10px 10px 8px 10px;
    background-color: #e50064;
    color: white;
    transition: background-color 250ms;

    &:hover {
      background-color: #ff006f;
      color: white;
    }
  }
  .textile-img {
    width: 100%;
  }

  .title-name {
    text-align: center;
  }
}

.container-textile-list {
  display: grid;
  grid-template-columns: calc(33.3% - 6px) calc(33.3% - 6px) calc(33.3% - 6px);
  column-gap: 10px;
  row-gap: 15px;
  width: 100%;
  @media (min-width: 600px) {
    & {
      grid-template-columns: calc(20% - 8px) calc(20% - 8px) calc(20% - 8px) calc(20% - 8px) calc(20% - 8px);
    }
  }

  .textile-img {
    width: 100%;
  }

  .title-name {
    text-align: center;
    color: black;
  }

  img {
    border-radius: 7px;
  }
}

.container-presentation {
  max-height: 80vh;
  background-color: white;
}

.textiles-container {
  .MuiPaper-root {
    background-color: white;
  }
}