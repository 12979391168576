.accordionDetailsProduct {
  // .accordion {
  //   background-color: #454353;
  //   color: white;
  //   box-shadow:
  //       0px 3px 2px -1px rgba(255, 255, 255,0.2),
  //       0px 3px 13px 0px rgba(0, 74, 119,0.14),
  //       0px 0px 0px 0px rgba(0, 74, 119,0.12);
  // }
  // .MuiIconButton-root {
  //   color: white !important;
  // }

  .accordion {
    background-color: white;
    color: black;
    box-shadow:
        0px 3px 2px -1px rgba(255, 255, 255,0.2),
        0px 3px 13px 0px rgba(0, 74, 119,0.14),
        0px 0px 0px 0px rgba(0, 74, 119,0.12);
  }
  .MuiIconButton-root {
    color: black !important;
  }

  .MuiAccordion-root:before {
    background-color: white;
  }

  .MuiAccordionDetails-root {
    border-top: solid #ffffff29 1px;
  }

  .element-list {
    display: grid;
    grid-template-columns: calc(50% - 6px) calc(50% - 6px);
    column-gap: 10px;
    row-gap: 15px;
    width: 100%;
    @media (min-width: 600px) {
      & {
        grid-template-columns: calc(33.3% - 6px) calc(33.3% - 6px) calc(33.3% - 6px);
      }
    }
  }

  a {
    text-decoration: none;
  }

  .download-file {
    width: 100%;
  }

  .container-img {
    img {
      width: 100%;
      border-radius: 5px;
    }
  }

  .container-img-certifications {
    display: flex;
    align-items: center;
    img {
      width: 100%;
      border-radius: 5px;
    }
  }
}