
.section-catalogue {
  margin-top: 100px;
  .container-2-columns {
    display: grid;
    grid-template-columns: auto;
    column-gap: 10px;
    row-gap: 15px;

    @media (min-width: 600px) {
      & {
        grid-template-columns: 60% auto;
      }
    }
  }

  .container-img {
    img {
      width: 100%;
    }
  }

  .container-info-center {
    max-width: 500px;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    text-align: center;
    color: #010A44;
    
    @media (min-width: 600px) {
      & {
        padding: 0 40px;
        text-align: left;
        justify-content: left;
      }
    }
  }

  h2 {
    font-size: 20px;
    line-height: 1;
    color: black;

    @media (min-width: 600px) {
      & {
        font-size: 35px;
        line-height: 1;
      }
    }
  }

  .btn {
    text-decoration: none;
    background: #1D2547;
    text-transform: uppercase;
    padding: 27px 44px;
    color: #fff;
    cursor: pointer;
    font-weight: 500;
    font-size: 15px;
    display: inline-block;
    line-height: 1;
    -moz-user-select: none;
    letter-spacing: 1px;
    line-height: 0;
    margin-bottom: 0;
    cursor: pointer;
    transition: color 0.4s linear;
    position: relative;
    z-index: 1;
    border: 0;
    overflow: hidden;
    margin: 0;
    border-radius: 0px;
  }

  .btn:hover::before {
    transform: scaleX(1);
    color: #fff !important;
    z-index: -1;
  }

  .btn::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 101%;
    height: 101%;
    background: #D70056;
    z-index: 1;
    border-radius: 0px;
    transition: transform 0.5s;
    transition-timing-function: ease;
    transform-origin: 0 0;
    transition-timing-function: cubic-bezier(0.5, 1.6, 0.4, 0.7);
    transform: scaleX(0);
    border-radius: 0px;
  }
}