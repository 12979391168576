
.search-component {
  .embed-responsive-item {
    width: 100%;
    height: calc(100vh - 75px);
  }
}
.title-products-list {
  text-align: center;
  width: 100%;
}
