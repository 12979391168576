@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  font-family: "Poppins",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-center {
  text-align: center;
}

$sizes: 12;


@for $i from 0 through $sizes {
  $padding: $i * 0.25rem;
  /* padding #{$padding} */
  .p-#{$i} {padding: $padding;}
  .pl-#{$i} {padding-left: $padding;}
  .pr-#{$i} {padding-right: $padding;}
  .pt-#{$i} {padding-top: $padding;}
  .pb-#{$i} {padding-bottom: $padding;}
  .px-#{$i} {padding-left: $padding; padding-right: $padding;}
  .py-#{$i} {padding-top: $padding; padding-bottom: $padding;}
}



@for $i from 0 through $sizes {
  $margin: $i * 0.25rem;
  /* margin #{$margin} */
  .m-#{$i}  {margin: $margin;}
  .ml-#{$i} {margin-left: $margin;}
  .mr-#{$i} {margin-right: $margin;}
  .mt-#{$i} {margin-top: $margin;}
  .mb-#{$i} {margin-bottom: $margin;}
  .mx-#{$i} {margin-left: $margin; margin-right: $margin;}
  .my-#{$i} {margin-top: $margin; margin-bottom: $margin;}
}
