@import './../../../vars.scss';

.container-navbar-desktop {
  margin-bottom: 0px;


  header {
    box-shadow:
        0px 3px 2px -1px rgba(255, 255, 255,0.2),
        0px 3px 13px 0px rgba(0, 74, 119,0.14),
        0px 0px 0px 0px rgba(0, 74, 119,0.12);
    position: relative !important;
  }

  .img-logo {
    margin: 7px 15px;
    width: 200px;
  }

  .MuiAppBar-colorPrimary {
    background-color: #ffffff !important;
    color: black;
  }

  #navigation {
    margin: 0;
    padding: 0;
  }

  .d-lg-block {
    display: block!important;
  }

  .main-menu ul li {
    display: inline-block;
    position: relative;
    z-index: 1;

    &:hover > a {
      color: #D70056;
    }

    &:hover > ul.submenu {
      visibility: visible;
      opacity: 1;
      top: 80%;
    }

    a, span {
      color: #1D2547;
      font-weight: 600;
      padding: 24px 19px;
      display: block;
      font-size: 14px;
      font-family: "Poppins",sans-serif;
      -webkit-transition: .3s;
      -moz-transition: .3s;
      -o-transition: .3s;
      transition: .3s;
      text-transform: capitalize;
      text-decoration: none;

      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: -moz-none;
      -o-user-select: none;
      user-select: none;
    }

    ul.submenu {
      position: absolute;
      width: 170px;
      background: #fff;
      left: 0;
      top: 90%;
      visibility: hidden;
      opacity: 0;
      padding: 17px 0;
      border-top: 3px solid #D70056;
      -webkit-transition: .3s;
      -moz-transition: .3s;
      -o-transition: .3s;
      transition: .3s;
      border-radius: 5px 5px 0 0;

      &::before {
        border-style: solid;
        border-width: 0 6px 6px 6px;
        border-color: transparent transparent #D70056 transparent;
        content: "";
        top: -7px;
        left: 13%;
        position: absolute;
        transition: .3s;
        z-index: -1;
        overflow: hidden;
        -webkit-transition: .3s;
        -moz-transition: .3s;
        -o-transition: .3s;
        transition: .3s;
      }

      li {
        a {
          padding: 6px 10px !important;
          font-size: 16px;
          text-transform: capitalize;
        }
      }
    }
  }
}