
.select-productslist {
  .title-products-list {
    text-align: center;
    width: 100%;
  }

  .list {
    display: grid;
    grid-template-columns: calc(50% - 5px) calc(50% - 5px);
    column-gap: 10px;
    row-gap: 15px;

    @media (min-width: 600px) {
      & {
        grid-template-columns: calc(25% - 8px) calc(25% - 8px) calc(25% - 8px) calc(25% - 8px);
      }
    }
  }
}