.carousell {
  padding: 10px;
  width: 100%;
  position: relative;
  margin-bottom: 25px;
}

.carousell__container {
  display: flex;
  justify-content: center;
  gap: 15px 5px;
  flex-wrap: wrap;
  margin: 10px auto;
  padding: 10px;
}

.carousell-item {
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 170px;
  height: 220px;
  margin-right: 10px;
  cursor: pointer;
  transition: 450ms all;
}

.carousell-item__img {
  width: 170px;
  height: 220px;
  -o-object-fit: cover;
  object-fit: cover;
}

.carousell-item:hover {
  opacity: 0.7;
  transform: scale(1.05);
}