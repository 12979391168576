
.select-note {
  box-shadow:
        0px 3px 2px -1px rgba(255, 255, 255,0.2),
        0px 3px 13px 0px rgba(0, 74, 119,0.14),
        0px 0px 0px 0px rgba(0, 74, 119,0.12);
  a {
    text-decoration: none;
  }
  .container-text {
    background-color: #1d2547;
    color: white;
  }
  .summarize {
    color: #C2C5DB;
  }
  .img-note {
    height: 290px;
    @media (min-width: 600px) {
      & {
        height: 600px;
      }
    }
  }

  .card {
    border: none !important;
  }
}