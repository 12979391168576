
.alliances-component {
  h2 {
    font-size: 17px;
    @media (min-width: 600px) {
      & {
        font-size: 25px;
      }
    }
  }
  .container-imgs {
    display: grid;
    grid-template-columns: 100%;
    column-gap: 10px;
    row-gap: 50px;
    margin-bottom: 130px;

    @media (min-width: 600px) {
      & {
        grid-template-columns: calc(33.3% - 7px) calc(33.3% - 7px) calc(33.3% - 7px);
      }
    }

    img {
      box-shadow:
        0px 3px 2px -1px rgba(255, 255, 255,0.2),
        0px 3px 13px 0px rgba(0, 74, 119,0.14),
        0px 0px 0px 0px rgba(0, 74, 119,0.12);
      border-radius: 10px;
    }
  }
}