@import './../../../vars.scss';

.container-navbar {

  .img-logo {
    width: 200px;
    padding: 10px 0;
  }

  .container-logo {
    text-align: center;
    margin: 8px 0 0px 0;
    padding-bottom: 0px;
    border-bottom: solid 2px #ededed;
  }

  .btn-icon-menu {
    z-index: 1400;
    background-color: white;
    position: fixed;
    right: 50px;
    top: 25px;
    width: 30px;
    height: 30px;
    min-height: auto;
    min-width: auto;
    padding: .7em;
    box-sizing: content-box;
    transition: right 200ms;
    // box-shadow:
    //   0px 3px 5px -1px rgba(255, 255, 255,0.2),
    //   0px 6px 10px 0px rgba(0, 74, 119,0.14),
    //   0px 1px 18px 0px rgba(0, 74, 119,0.12);
    box-shadow:
        0px 3px 2px -1px rgba(255, 255, 255,0.2),
        0px 3px 13px 0px rgba(0, 74, 119,0.14),
        0px 0px 0px 0px rgba(0, 74, 119,0.12);
    .icon-show-menu {
      transform: rotate(0deg);
      transition: transform 300ms;
    }

    &.close {
      right: 240px;
      .icon-show-menu {
        transform: rotate(-90deg);
      }
    }
  }

  @media (max-width: $sm) {
    .btn-icon-menu {
      top: 15px;
      right: 15px;
      width: 30px;
      height: 30px;
    }
  }
}

.icon-show-menu {
  width: 100%;
}

.container-close-menu {
  margin: 80px 0;
  .btn-icon-menu-close {
    z-index: 99;
    background-color: white;
    right: -30px;
    top: 5%;
    width: 2em;
    height: 2em;
    min-height: auto;
    min-width: auto;
    padding: .7em;
    box-sizing: content-box;
    .icon-show-menu {
      transform: rotate(-90deg);
    }
  }  
  .MuiFab-root {
    box-shadow: none !important;
  }
}

@media (max-width: $sm) {
  .container-close-menu  {
    margin: 65px 0;
  }
}

.navBar-right {
  .MuiPaper-root {
    width: 19em;
  }

  .MuiListItem-button {
    color: black;
  }

  .MuiListItem-button:hover {
    background-color: #d6005a !important;
    color: white;
  }

  .MuiBackdrop-root {
    background-color: #d6005a38 !important;
  }
  
  .MuiPaper-root {
    color: white !important;
    background-color: #ededed !important;
  }

  .split-section {
    margin: 1em;
    font-style: italic;
    font-weight: 100;
    color: black;
  }

  .container-menu-secundary {
    a {
      div {
        padding-left: 21px;
      }
    }
  }
}

.container-links-navbar {
  a {
    font-size: inherit;
    font-style: inherit;
    color: inherit;
    text-decoration: none;
  }
}

.list-secondary-pages {
  span {
    font-size: 14px;
  }
}