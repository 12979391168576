
.new-products-component {
  padding: 80px 0 0 0;

  h1 {
    font-size: 36px;
    display: block;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  .sub-title {
    color: #7F85A8;
  }

  .MuiTabs-flexContainer {
    justify-content: center;

    @media (max-width: 1024px) {
      & {
        justify-content: initial;
      }
    }
  }

  header {
    // box-shadow:
    //   0px 3px 5px -1px rgba(255, 255, 255,0.2),
    //   0px 6px 10px 0px rgba(0, 74, 119,0.14),
    //   0px 1px 18px 0px rgba(0, 74, 119,0.12);
    box-shadow:
        0px 3px 2px -1px rgba(255, 255, 255,0.2),
        0px 3px 13px 0px rgba(0, 74, 119,0.14),
        0px 0px 0px 0px rgba(0, 74, 119,0.12);
    background-color: white !important;
  }

  .container-new-products {
    position: relative;
    .arrow {
      position: absolute;
      top: calc(50% - 24px);
      z-index: 99;
      border-radius: 100px;
      // box-shadow:
      //   0px 3px 5px -1px rgba(255, 255, 255,0.2),
      //   0px 6px 10px 0px rgba(0, 74, 119,0.14),
      //   0px 1px 18px 0px rgba(0, 74, 119,0.12);

      box-shadow:
        0px 3px 2px -1px rgba(255, 255, 255,0.2),
        0px 3px 13px 0px rgba(0, 74, 119,0.14),
        0px 0px 0px 0px rgba(0, 74, 119,0.12);
    }

    .previous {
      left: 0;
    }

    .next {
      right: 0;
    }
  }
  .container-item {
    & > div {
      padding: 24px 50px;
      margin-top: 30px;
    }
  }

  .container-products {
    display: grid;
    grid-template-columns: auto;
    column-gap: 10px;
    row-gap: 15px;

    @media (min-width: 600px) {
      & {
        grid-template-columns: calc(33.3% - 6px) calc(33.3% - 6px) calc(33.3% - 6px);
      }
    }
  }

  .MuiTab-root  {
    color: #1D2547;
  }
}