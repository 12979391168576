
.home-component {
  .embed-responsive-item {
    width: 100%;
    height: calc(100vh - 74px);
  }
}
.loadView {
  position: fixed;
  height: 100vh;
  width: 100%;
  background-color: rgba(153, 0, 127, 0.356);
  z-index: 999;
  display: none;
  &.show {
    display: block;
  }
}
