.select-banner {
  background-color: #1d2547;
  margin-bottom: 50px;
  height: 400px;
  box-shadow:
        0px 3px 2px -1px rgba(255, 255, 255,0.2),
        0px 3px 13px 0px rgba(0, 74, 119,0.14),
        0px 0px 0px 0px rgba(0, 74, 119,0.12);

  .image-banner {
    padding: 120px 0px;
    object-fit: cover;
    height: 400px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    .MuiBreadcrumbs-ol {
      color: white;
      background-color: #1d25476b;
      border-radius: 4px;
      padding: 5px 15px;
      display: inline-flex;
    } 

    a, p {
      color: white;
      text-decoration: none;
    }

  }

  .title-main {
    color: white;
    padding: 12px 15px;
    font-size: 30px;
    font-weight: bold;
    background-color: #1d25476b;
    margin-bottom: 20px;
    border-radius: 5px;
  }
}