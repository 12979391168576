.product-gallery-component {
  max-width: 400px;
  margin: auto;
  .img {
    width: 100%;
  }

  li {
    text-align: center;
  }

  .lSPager {
    justify-content: center;
    display: flex;
    width: 100% !important;
    li {
      width: 110px  !important;
    }
  }
}