
.component-distributor-list {
    .card {
        box-shadow:
            0px 3px 2px -1px rgba(255, 255, 255,0.2),
            0px 3px 13px 0px rgba(0, 74, 119,0.14),
            0px 0px 0px 0px rgba(0, 74, 119,0.12) !important;
        border: none;
        height: 100%;
    }
    .distributor-card {
        padding: 15px;
    }
}