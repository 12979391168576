
.product-component {
  #container {
    display: grid;
    grid-template-columns: 100%;
    column-gap: 10px;
    row-gap: 15px;
    width: 100%;
  }

  @media (min-width: 600px) {
    #container {
      grid-template-columns: calc(50% - 6px) calc(50% - 6px);
    }

    #sidebar {
      position: sticky;
      height: -webkit-fill-available;
      top: 100px;
    }
  }

  #content {
    width: 100%;
  }

  .code-main {
    font-weight: bold;
    color: black;
    font-size: 40px;
  }
}