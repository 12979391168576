
.select-distributor-map {

  .color-white {
    color: white;
  }

  .iframe-map {
    width: 100%;
    height: calc(100vh - 77px);
    border: 0;
  }

  .btn-map-link {
    a {
      color: white;
      text-decoration: none;
    }
  }
}