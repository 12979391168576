

.section-footer {
  background-color: #1D2547;
  padding: 30px;
  color: #C2C5DB;

  h4 {
    color: white;
  }

  .container-columns {
    display: grid;
    grid-template-columns: auto;
    column-gap: 10px;
    row-gap: 15px;

    @media (min-width: 600px) {
      & {
        grid-template-columns: auto auto auto auto;
      }
    }
  }

  .footer-social {
    display: flex;

    a {
      color: #fff;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      font-size: 14px;
      line-height: 40px;
      display: inline-block;
      text-align: center;
      margin-right: 8px;
      border: 1px solid #fff;
      transition: all 500ms;
      align-items: center;
      justify-content: center;
      display: flex;
    }
  }

  .footer-social a:hover {
    color: #fff;
    border: 1px solid transparent;
    background: #D70056;
  }

  a {
    color: #C2C5DB;
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }

  .copyright {
    text-align: center;
    color: #646D77;
    font-size: 12px;
  }
}